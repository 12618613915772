import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { supaClient } from "../supa-client";
import { registerFormConfig } from "../utils/form-config";
import Button from "./button";
import PhoneInput from "./phone-input";
import UsernameInput from "./username-input";
import VerifyInput from "./verify-input";

import PropTypes from "prop-types";

RegisterForm.propTypes = {
  index: PropTypes.number.isRequired,
  onStep: PropTypes.func.isRequired,
  _userPhoneNumber: PropTypes.string.isRequired,
  _countryCode: PropTypes.string.isRequired,
};

export default function RegisterForm({
  index,
  onStep,
  _userPhoneNumber,
  _countryCode,
}) {
  const userContext = useContext(UserContext);
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [countryCode, setCountryCode] = useState(_countryCode);
  const [phoneNumber, setPhoneNumber] = useState(_userPhoneNumber);
  const [userOTP, setUserOTP] = useState("123456");
  const [userUsername, setUserUsername] = useState("");
  const [formError, setFormError] = useState(false);

  const updateUserPhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  const updateUserOTP = (value) => {
    setUserOTP(value);
  };

  const updateUserUsername = (value) => {
    setUserUsername(value);
  };

  const flowStep = [
    {
      ...registerFormConfig.phoneNumber,
      onChange: (e) => {
        updateUserPhoneNumber(e.target.value);
      },
      buttonHandler: () => {
        setFormError("");
        supaClient.auth
          .signInWithOtp({
            phone: `${countryCode}${phoneNumber}`,
          })
          .then(({ data, error }) => {
            if (error) {
              console.error(error.message);
              setFormError("😖 Looks like things might be down on our end");
            } else {
              onStep();
            }
          });
      },
    },
    {
      ...registerFormConfig.otpVerify,
      onChange: (value) => {
        updateUserOTP(value);
      },
      buttonHandler: () => {
        setFormError("");

        supaClient.auth
          .verifyOtp({
            phone: `${countryCode}${phoneNumber}`,
            token: userOTP,
            type: "sms",
          })
          .then(({ data, error }) => {
            if (error) {
              /*
              send user back to putting phone number... delete account lowkey?
              AuthApiError: Token has expired or is invalid
                  at handleError2 (@supabase_supabase-js.js?v=cad6331d:4150:9)
                  at async _handleRequest2 (@supabase_supabase-js.js?v=cad6331d:4191:5)
                  at async _request (@supabase_supabase-js.js?v=cad6331d:4175:16)
                  at async SupabaseAuthClient.verifyOtp (@supabase_supabase-js.js?v=cad6331d:5126:31)
              */
              console.error(error.message);
              setFormError("😖 Looks like things might be down on our end");
              throw error;
            }
            onStep();
          });
      },
    },
    {
      ...registerFormConfig.personalInfo,
      onChange: (value) => {
        updateUserUsername(value);
      },
      buttonHandler: () => {
        setFormError("");
        supaClient
          .from("user_profiles")
          .insert([
            {
              //TODO: handle userContext not existing for whatever reason better
              user_id: userContext.session?.user.id || "",
              username: userUsername,
              created_at: userContext.session?.user.created_at || "",
            },
          ])
          .then(async ({ error }) => {
            if (error) {
              console.error(error);
              if (error.code === "23505") {
                setFormError("ERROR: Username is already taken");
              } else if (error.code === "23514") {
                setFormError(
                  "At least 3 letters, numbers and underscores only, SORRY! 🤧"
                );
              } else {
                console.error(error.message);
                setFormError("😖 Looks like things might be down on our end");
              }
              return;
            } else {
              await userContext.refreshProfile(userContext.session?.user.id);
            }
          });
      },
    },
  ];

  useEffect(() => {
    const currentInputLength = flowStep[index].inputLength;
    const currentInputValue = flowValues[index];
    if (flowStep[index].inputLength === -1) {
      setIsFormCompleted(currentInputValue.length >= 1);
    } else {
      setIsFormCompleted(currentInputValue.length === currentInputLength);
    }
  }, [phoneNumber, userOTP, userUsername, index]);

  const flowValues = [phoneNumber, userOTP, userUsername];

  let input = <></>;
  if (flowStep[index].inputType == "phone") {
    input = (
      <PhoneInput
        onChange={flowStep[index].onChange}
        updateState={setIsFormCompleted}
        updateCountryState={setCountryCode}
        title={flowStep[index].inputTitle}
        country={flowStep[index].country}
      />
    );
  } else if (flowStep[index].inputType == "verify") {
    input = (
      <VerifyInput
        onChange={flowStep[index].onChange}
        updateState={setIsFormCompleted}
        title={flowStep[index].inputTitle}
        country={flowStep[index].country}
      />
    );
  } else if (flowStep[index].inputType == "username") {
    input = (
      <UsernameInput
        onChange={flowStep[index].onChange}
        updateState={setIsFormCompleted}
        title={flowStep[index].inputTitle}
        country={flowStep[index].country}
      />
    );
  }
  return (
    <form className="login-form">
      <div className="header">
        <h4>{flowStep[index].header}</h4>
        {flowStep[index].description && (
          <p>
            {flowStep[index].description}
            {index === 1 && `${countryCode}${phoneNumber}`}
          </p>
        )}
      </div>
      <div className="input-container">
        {/* Input Fields */}
        <div className="inputs"> {input} </div>
        <p>
          {flowStep[index].footer}
          {flowStep[index].link}
        </p>
      </div>
      {formError && (
        <p id="register-form-error" className="error-text">
          {formError}
        </p>
      )}
      <Button
        label={flowStep[index].buttonLabel}
        style={"window-black"}
        disabled={!isFormCompleted}
        onClick={() => {
          setIsFormCompleted(false);
          flowStep[index].buttonHandler
            ? flowStep[index].buttonHandler()
            : onStep();
        }}
      ></Button>
    </form>
  );
}
