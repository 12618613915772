import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { supaClient } from "../supa-client";
import { loginFormConfig } from "../utils/form-config";
import Button from "./button";
import "./login-form.css";
import PhoneInput from "./phone-input";
import UsernameInput from "./username-input";
import VerifyInput from "./verify-input";

LoginForm.propTypes = {
  index: PropTypes.number.isRequired,
  onStep: PropTypes.func.isRequired,
  setActiveForm: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  initialCountryCode: PropTypes.string,
  initialPhoneNumber: PropTypes.string,
};
export default function LoginForm({
  index,
  onStep,
  setActiveForm,
  setPageIndex,
  initialCountryCode = "+1",
  initialPhoneNumber = "",
}) {
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [countryCode, setCountryCode] = useState(initialCountryCode);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [userOTP, setUserOTP] = useState("123456");
  const [formError, setFormError] = useState("");

  const handleRegisterChoice = (choice) => {
    if (choice === "yes") {
      supaClient.auth
        .signInWithOtp({
          phone: `${countryCode}${phoneNumber}`,
        })
        .then(({ data, error }) => {
          if (error) {
            console.error(error);
            setFormError("😖 Looks like things might be down on our end");
          } else {
            setActiveForm("register", countryCode, phoneNumber);
            setIsFormCompleted(false);
            const tabs = document.querySelectorAll(".card-tab");
            tabs.forEach((t) => t.classList.remove("selected"));
            document
              .querySelector(`.card-tab-${"register"}`)
              .classList.add("selected");
          }
        });
    } else {
      setFormError(
        "🙁 Don't know what to tell ya mate... no sign up... no plus",
      );
    }
  };

  const updateUserPhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  const updateUserOTP = (value) => {
    setUserOTP(value);
  };

  const flowStep = [
    {
      ...loginFormConfig.phoneNumber,
      onChange: (e) => {
        updateUserPhoneNumber(e.target.value);
      },
      buttonHandler: () => {
        setFormError("");

        supaClient.auth
          .signInWithOtp({
            phone: `${countryCode}${phoneNumber}`,
            options: {
              shouldCreateUser: false,
            },
          })
          .then(({ data, error }) => {
            if (error) {
              if (error.message === "Signups not allowed for otp") {
                setPageIndex(2); //change to account not found view
              } else {
                console.error(error);
                setFormError(
                  "😖 Looks like that didn't work... Maybe try again? 🥺",
                );
              }
            } else {
              onStep();
            }
          });
      },
    },
    {
      ...loginFormConfig.otpVerify,
      onChange: (value) => {
        updateUserOTP(value);
      },
      buttonHandler: () => {
        setFormError("");

        supaClient.auth
          .verifyOtp({
            phone: `${countryCode}${phoneNumber}`,
            token: userOTP,
            type: "sms",
          })
          .then(({ error }) => {
            if (error) {
              setFormError(
                "😖 Looks like that didn't work... Maybe try again? 🥺",
              );
              throw error;
            }
          });
      },
    },
    {
      ...loginFormConfig.registerPrompt,
      buttonHandler: () => {
        handleRegisterChoice("yes");
      },
    },
  ];

  // This useEffect updates the completion status of the current login step
  // based on whether the input (phone number or OTP) matches the required length
  useEffect(() => {
    const currentInputLength = flowStep[index].inputLength;
    const currentInputValue = index === 0 ? phoneNumber : userOTP;
    setIsFormCompleted(currentInputValue.length === currentInputLength);
  }, [phoneNumber, userOTP, index]);

  let input = <></>;
  if (flowStep[index].inputType == "phone") {
    input = (
      <PhoneInput
        onChange={flowStep[index].onChange}
        updateState={setIsFormCompleted}
        updateCountryState={setCountryCode}
        title={flowStep[index].inputTitle}
        country={flowStep[index].country}
      />
    );
  } else if (flowStep[index].inputType == "verify") {
    input = (
      <VerifyInput
        onChange={flowStep[index].onChange}
        updateState={setIsFormCompleted}
        title={flowStep[index].inputTitle}
        country={flowStep[index].country}
      />
    );
  } else if (flowStep[index].inputType == "username") {
    input = (
      <UsernameInput
        onChange={flowStep[index].onChange}
        updateState={setIsFormCompleted}
        title={flowStep[index].inputTitle}
        country={flowStep[index].country}
      />
    );
  }

  return (
    <form className="login-form">
      <div className="header">
        <h4>{flowStep[index].header}</h4>
        {flowStep[index].description && (
          <p>
            {flowStep[index].description}
            {index === 1 && `${countryCode}${phoneNumber}`}
          </p>
        )}
      </div>
      <div className="input-container">
        {/* Input Fields */}
        <div className="inputs">{input}</div>
        <p>{flowStep[index].footer}</p>
      </div>

      {formError && (
        <p id="register-form-error" className="error-text">
          {formError}
        </p>
      )}
      <Button
        label={flowStep[index].buttonLabel}
        style={"window-black"}
        disabled={!isFormCompleted}
        onClick={() => {
          setIsFormCompleted(false);
          {
            flowStep[index].buttonHandler
              ? flowStep[index].buttonHandler()
              : onStep();
          }
        }}
      ></Button>
    </form>
  );
}
