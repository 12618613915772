import "./login-register-new.css";

import { useState } from "react";

import PropTypes from "prop-types";
import Card from "./card";
import LoginForm from "./login-form";
import RegisterForm from "./register-form";

LoginRegisterNew.propTypes = {
  _activeForm: PropTypes.string,
};

export default function LoginRegisterNew({ _activeForm = "login" }) {
  const [activeForm, setActiveForm] = useState(_activeForm);
  const [pageIndex, setPageIndex] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleTabClick = (form) => {
    setActiveForm(form);
    setPageIndex(0);
    setCompletedSteps(0);
  };

  const handleArrowClick = (step) => {
    /* Edge Case: Account Not Found */
    if (pageIndex === 2 && completedSteps === 0) {
      setPageIndex(0);
      setCompletedSteps(0);
      return;
    }

    if (pageIndex + step > completedSteps - 1 || pageIndex + step < 0) return;
    setPageIndex(pageIndex + step);
    setCompletedSteps(pageIndex + step);
  };

  const handleStepComplete = (step) => {
    if (step === 0) {
      console.error("ERROR: Step not complete");
    }

    setCompletedSteps(completedSteps + 1);
    setPageIndex(pageIndex + 1);
  };

  const handleSetActiveForm = (form, country_code, phone = "") => {
    setCompletedSteps(0);
    setPhoneNumber(phone);
    setCountryCode(country_code);
    setActiveForm(form);
    setPageIndex(1);
  };

  return (
    <div className="login-register-new">
      <Card
        url={activeForm + "/" + (pageIndex + 1)}
        tabs={["login", "register"]}
        onTabClick={handleTabClick}
        onArrowClick={handleArrowClick}
        title=""
        pageIndex={pageIndex}
        activeForm={activeForm}
        initialCountryCode={countryCode}
        initialPhoneNumber={phoneNumber}
      >
        {activeForm === "login" ? (
          <LoginForm
            index={pageIndex}
            onStep={handleStepComplete}
            setActiveForm={handleSetActiveForm}
            setPageIndex={setPageIndex}
          />
        ) : (
          <RegisterForm
            index={pageIndex}
            onStep={handleStepComplete}
            _userPhoneNumber={phoneNumber}
            _countryCode={countryCode}
          />
        )}
      </Card>
    </div>
  );
}
