import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import BetaMiniSite from "./beta-mini-site";
import "./home.css";
import LoginRegisterNew from "./login-register-new";

export default function Home() {
  const { profile, loading } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return <>{profile ? <BetaMiniSite /> : <LoginRegisterNew />}</>;
}
