import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <p className="read-the-docs">
        Want to{" "}
        <a target="_blank" href="http://www.fortytwoco.net">
          shop normally?
        </a>
      </p>
      <p className="watermark">Developed by TECHPACK</p>
    </div>
  );
}
